$color-danger: #CE3A3C;
$color-warning: #f9e608;
$color-success: #2acc5c;
$color-info: #11d6f0;
$color-light-grey: #bebebe;
$color-grey: #888;
$color-mid-grey: #242323;
$color-dark-grey: #191919;
$color-dark: #101010;

$color-font: black;
