.contact-icons {
    .icon {
        margin-right: 15px;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

}

.job-opportunity {
    margin-top: 80px;
}

.form-link {
    margin-left: 5px;
    margin-right: 5px;
}