@import '../../assets/styles/screen.scss';

.about-description-container {
    h2 {
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 15px;
    }
}

.about-description-section {
    width: 100%;

    @media (min-width: $screen-lg) {
        width: 50%;
    }
}

.about-description {
    margin-bottom: 30px;
}

.icon-guy-programming {
    padding: 40px;
    margin: auto;
    text-align: center;

    @media (min-width: $screen-lg) {
        padding-top: 0;
        width: 50%;
        padding-bottom: 0;
    }

    img {
        max-width: 100%;

        @media (min-width: $screen-lg) {
            width: 350px;
        }
    }
}

.description-summary {
    justify-content: center;

    @media (min-width: 900px) {
        display: flex;
        justify-content: left;
        margin-bottom: 60px;
    }

    .icon {
        background-color: #11d6f0;
        padding: 10px;
        border-radius: 25px;
        width: 40px;
        height: 40px;
    }

    .icon-info {
        padding-left: 10px;

        h6 {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
        }

        p {
            margin-bottom: 0;
        }
    }

    .summary {
        margin-right: 15px;
        margin-bottom: 15px;
    }
}

.service-icon {
    width: 100%;
    padding-right: 0;
    display: block;
    float: left;
    margin-bottom: 10px;
}

.call-to-action-resume {
    display: block;
    margin: auto auto 60px auto;
}