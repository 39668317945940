@import "./colors";

$color_btn_dark: $color-dark-grey;
$color_btn_primary: $color-info;
$color_btn_secondary: $color-light-grey;
$color_btn_success: $color-success;
$color_btn_danger: $color-danger;
$color_btn_warning: $color-warning;
$color_btn_light: $color-success;
$color_btn_success_rh: $color-success;
$color_btn_disabled: #f5f5f5;

$font_size_btn_sm: 16px;
$font_size_btn_lg: 20px;

button {
  cursor: pointer;
}

.btn {
  min-width: 100px;
  font-size: 1rem;
  line-height: 1.5;
  border: 0px;
  border-radius: 25px;
  border-color: transparent;
  padding: 8px 15px;
  height: 40px;
  text-decoration: none;

  &.btn-success:disabled,
  &.btn-dark:disabled,
  &.btn-primary:disabled,
  &.btn-secondary:disabled,
  &.btn-success-rh:disabled,
  &.btn-danger:disabled,
  &.btn-warning:disabled,
  &.btn-light:disabled {
    color: #bebebe;
    background-color: $color_btn_disabled;
    border: 0px;
    cursor: auto;
  }

  &:active,
  &:focus {
    border-color: transparent;
    outline: none;
    box-shadow: none;
  }

  &:hover {
    text-decoration: none;
  }
}

.btn-dark {
  color: white;
  background-color: $color_btn_dark;

  &:hover {
    background-color: darken($color: $color_btn_dark, $amount: 5%);
    border-color: transparent;
  }

  &:active {
    background-color: darken($color: $color_btn_dark, $amount: 10%);
    border-color: transparent;
  }
}

.btn-primary {
  color: white;
  background-color: $color_btn_primary;

  &:hover {
    color: white;
    background-color: darken($color: $color_btn_primary, $amount: 5%);
  }

  &:active {
    color: white;
    background-color: darken($color: $color_btn_primary, $amount: 10%);
  }
}

.btn-secondary {
  color: black;
  background-color: $color_btn_secondary;

  &:hover {
    color: black;
    background-color: darken($color: $color_btn_secondary, $amount: 5%);
  }

  &:active {
    color: black;
    background-color: darken($color: $color_btn_secondary, $amount: 10%);
  }
}

.btn-success {
  color: white;
  background-color: $color_btn_success;

  &:hover {
    background-color: darken($color: $color_btn_success, $amount: 5%);
  }

  &:active {
    background-color: darken($color: $color_btn_success, $amount: 10%);
  }
}

.btn-success-rh {
  color: white;
  background-color: $color_btn_success_rh;

  &:hover {
    background-color: darken($color: $color_btn_success_rh, $amount: 5%);
  }

  &:active {
    background-color: darken($color: $color_btn_success_rh, $amount: 10%);
  }
}

.btn-danger {
  color: white;
  background-color: $color_btn_danger;

  &:hover {
    background-color: darken($color: $color_btn_danger, $amount: 5%);
  }

  &:active {
    background-color: darken($color: $color_btn_danger, $amount: 10%);
  }
}

.btn-warning {
  color: white;
  background-color: $color_btn_warning;

  &:hover {
    background-color: darken($color: $color_btn_warning, $amount: 5%);
  }

  &:active {
    background-color: darken($color: $color_btn_warning, $amount: 10%);
  }
}

.btn-light {
  color: $color_btn_primary;
  border: solid 1px $color_btn_primary;
  background-color: transparent;

  &:focus {
    border: solid 1px $color_btn_primary;
  }

  &:hover {
    color: white;
    background-color: darken($color: $color_btn_primary, $amount: 40%);
  }

  &:active {
    color: white;
    border: 0px;
    background-color: darken($color: $color_btn_primary, $amount: 10%);
  }
}

.btn-light-primary {
  color: white;
  border: solid 1px white;
  background-color: transparent;
  
  &:focus {
    border: solid 1px $color_btn_primary;
  }
  
  &:hover {
    color: $color_btn_primary;
    background-color: darken($color: $color_btn_primary, $amount: 40%);
    border-color: $color_btn_primary;
  }

  &:active {
    color: $color_btn_primary;
    background-color: darken($color: $color_btn_primary, $amount: 10%);
  }
}

.btn-light-danger {
  color: $color_btn_danger;
  border: solid 1px $color_btn_danger;
  background-color: white;

  &:focus {
    border: solid 1px $color_btn_danger;
  }

  &:hover {
    color: white;
    background-color: darken($color: $color_btn_danger, $amount: 5%);
  }

  &:active {
    color: white;
    background-color: darken($color: $color_btn_danger, $amount: 10%);
  }
}

.btn-sm {
  font-size: $font_size_btn_sm;
  height: 30px;
}

.btn-lg {
  font-size: $font_size_btn_lg;
  height: 50px;
}