@import '../../assets/styles/colors.scss';
@import '../../assets/styles/screen.scss';

.navbar {
    background-color: #242323;
    width: 285px;
    color: white;
    height: 100%;
    position: fixed;
    z-index: 20;
    display: none;
    right: 0;
    overflow: auto;
    padding-bottom: 50px;

    &.open {
        display: block;
        padding-top: 5px;
        padding-right: 5px;
        @media (min-width: $screen-lg) {
            padding-top: 0px;
            padding-right: 0px;
        }
    }

    @media (min-width: $screen-lg) {
        display: block;
        left: 0;
        right: auto;
        padding: 0;
    }
}

.profile {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 50px;
    
    @media (min-width: $screen-lg) {
        margin-top: 100px;
    }
}

.profile-image-container {
    width: 110px;
    height: 110px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 7px solid $color-dark-grey;
    margin: auto;
}

.profile-image {
    display: inline;
    margin: 0px -23px;
    height: 100%;
    width: auto;
}

.title-name {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.subtitle {
    color: #888;
}

.menu-icon {
    background-color: $color-info;
    border-radius: 25px;
    padding: 5px;
}

.header-main-menu {
    list-style: none;

    li {
        text-align: center;
        margin: 0px 15px 10px 0;


        @media (min-width: $screen-lg) {
            margin: 0px 0px 10px 35px;
        }
    }

    li a, li p {
        width: 100%;
        display: block;
        color: white;
        text-decoration: none;
        padding: 10px 13px 10px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;

        @media (min-width: $screen-lg) {
            padding: 10px 50px 10px 20px;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }

    li:hover,
    li.active-page {
        background-color: $color-dark-grey;
        cursor: pointer;
        border-radius: 0 25px 25px 0;

        @media (min-width: $screen-lg) {
            border-radius: 25px 0px 0px 25px;
        }
    }

    .menu-icon {
        width: 30px;
        height: 30px;
        @media (min-width: $screen-lg) {
            margin-right: 20px;
        }
    }
}

.soon {
    opacity: 0.3;
    cursor: not-allowed;
}

.copyrights {
    color: #888;
    text-align: center;
    margin-top: 30px;
}