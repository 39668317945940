@import '../../assets/styles/screen.scss';

header {
    padding: 5px;
    z-index: 15;
    color: black;
    background-color: white;
    display: flex;

    @media (min-width: $screen-lg) {
        display: none;
    }
}

.header-profile-image-container {
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 10px;
}

.header-profile-image {
    display: inline;
    margin: 0px -10px;
    height: 100%;
    width: auto;
}

.icon-menu {
    margin-left: auto;
    border: none;
    background-color: transparent;
    float: right;
}