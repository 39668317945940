@import './colors.scss';
@import './screen.scss';
@import '../fonts/roboto.scss';

body,
input,
select,
button {
  font-family: 'Roboto', sans-serif;
  color: $color-dark-grey;
}

.weight-normal {
  font-weight: normal;
}

a {
  cursor: pointer;
  color: $color-info;

  &:hover {
    color: $color-info;
    text-decoration: underline;
  }
}

p {
  font-size: 16px;
  line-height: 1.39;
  color: white;
  margin-bottom: 10px;
}

h1 {
  font-size: 40px;
  line-height: 1;
  
  @media (min-width: $screen-sm) {
    font-size: 45px;
  }
}

h2 {
  font-size: 30px;
  line-height: 1.2;
}

h3 {
  font-size: 25px;
  line-height: 1.2;
}

h4 {
  font-size: 18px;
  line-height: 1.11;
}

h5 {
  font-size: 16px;
  line-height: 1.25;
}

.text-lg {
  font-size: 25px;
}

.text-white {
  color: white;
}