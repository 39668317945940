.background-home {
    background-image: url('../../assets/images/home-background.jpeg');
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    opacity: 0.9;
}

.title {
    color: white;
    font-size: 30px;
    margin: auto;
    padding: 5px;
    text-align: center;
}

.home-options {
    .btn {
        font-weight: bold;
        margin: 30px 10px;
    }

    .icon {
        margin-right: 10px;
    }
}

.fade-in {
    /* Firefox */
    -moz-animation: fadein 2s;
    /* Safari and Chrome */
    -webkit-animation: fadein 2s;
    /* Opera */
    -o-animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {

    /* Firefox */
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {

    /* Safari and Chrome */
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-o-keyframes fadein {

    /* Opera */
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}