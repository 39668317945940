@import '../../assets/styles/screen.scss';
@import '../../assets/styles/colors.scss';

.section-head {
    align-items: center;
    margin-bottom: 30px;

    @media (min-width: $screen-lg) {
        display: flex;
    }

    h2 {
        font-size: 26px;
    }

    h3 {
        font-size: 20px;
    }

    h2,
    h3 {
        border-left: 1px solid rgba(255, 255, 255, 0.8);
        position: relative;
        padding: 0 0 0 15px;
        line-height: 1.4;
        border-left: 1px solid #eee;
        letter-spacing: 2px;
        font-weight: 600;

        &:before {
            content: '';
            width: 1px;
            height: 50%;
            background: #11d6f0;
            position: absolute;
            left: -1px;
            bottom: 0;
        }
    }


    h2 span,
    h3 span {
        display: block;
        color: rgba(255, 255, 255, 0.8);
    }

    .btn-download {
        margin-left: auto;
        margin-top: 30px;
        display: inline-block;

        .icon {
            margin-right: 5px;
        }

        @media (min-width: $screen-lg) {
            margin-top: 0;
        }
    }
}

.timeline {
    position: relative;
    margin: 0 auto;
    margin-bottom: 30px;
    padding-bottom: 100px;

    &:after {
        content: '';
        position: absolute;
        width: 6px;
        background-color: $color-mid-grey;
        top: 0;
        bottom: 0;
        margin-left: -30px;
        left: 31px;

        @media (min-width: $screen-lg) {
            left: 50%;
            margin-left: -3px;
        }
    }

    h5 {
        font-size: 18px;
        margin-bottom: 15px;
    }
}

.timeline .box {
    position: relative;
    background-color: inherit;
    width: 100%;
    padding-left: 45px;
    margin-top: 40px;

    &:after {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        right: -17px;
        background-color: $color-dark-grey;
        border: 4px solid $color-info;
        top: 45px;
        border-radius: 50%;
        z-index: 1;
    }


    .period {
        margin-bottom: 10px;
        display: block;
        color: rgba(255, 255, 255, 0.8);
    }

    .content {
        background-color: $color-mid-grey;
        position: relative;
        border-radius: 6px;
        padding: 15px 20px;
    }

    @media (max-width: $screen-lg - 1) {
        &:after {
            left: -12px;
        }

        &:before {
            left: 35px;
            border: solid #fff;
            border-width: 10px 10px 10px 0;
            border-color: transparent #fff transparent transparent;
        }
    }

    @media (min-width: $screen-lg) {
        width: 50%;
        padding: 0;

        .content {
            padding: 30px
        }
    }

    &.left {
        left: 0;

        &:before {
            content: " ";
            height: 0;
            position: absolute;
            top: 50px;
            width: 0;
            z-index: 1;
            right: 30px;
            border: solid $color-mid-grey;
            border-width: 10px 10px 10px 0;
            border-color: transparent $color-mid-grey transparent transparent;
        }

        @media (min-width: $screen-lg) {
            &:before {
                border-width: 10px 0 10px 10px;
                border-color: transparent transparent transparent $color-mid-grey;
            }

            .content {
                margin-right: 40px;
            }
        }

    }

    &.right {
        &:before {
            content: " ";
            height: 0;
            position: absolute;
            top: 50px;
            width: 0;
            z-index: 1;
            left: 35px;
            border: solid $color-mid-grey;
            border-width: 10px 10px 10px 0;
            border-color: transparent $color-mid-grey transparent transparent;
        }

        @media (min-width: $screen-lg) {
            left: 50%;

            &:after {
                left: -16px;
            }

            &:before {
                left: 30px;
            }

            .content,
            .period {
                margin-left: 40px;
            }
        }
    }

}

.education-section {
    margin-bottom: 30px;

    .education-box {
        background: $color-mid-grey;
        padding: 15px 30px;
        text-align: center;
        margin-bottom: 30px;
        height: 200px;
        
        @media (min-width: $screen-lg) {
            height: 280px;
            padding: 30px 30px;
        }
    }
    
    img {
        max-width: 100px;
        padding: 25px 0;
        text-align: center;
        
        @media (min-width: $screen-lg) {
            max-width: 70%;
            padding: 30px 0;
        }
    }

    .title {
        font-size: 18px;
        font-weight: 600;
    }
}