@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import './assets//styles/buttons.scss';
@import './assets//styles/forms.scss';
@import './assets//styles/grid.scss';
@import './assets//styles/screen.scss';
@import './assets//styles/text.scss';
@import './assets//styles/colors.scss';
@import './assets//styles/alerts.scss';

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #101010;
  color: white;
}

input,
button,
textarea {
  font: 400 18px Roboto, sans-serif;
}

button {
  cursor: pointer;
}

p {
  line-height: 25px;
}

.background-container {
  padding: 20px;

  @media (min-width: 900px) {
    padding: 30px 80px;
  }
}

.background-dark {
  background-color: #101010;
}

.row-desktop {
  @media (min-width: 900px) {
    display: flex;
  }
}

.title-section {
  margin-top: 50px;
  padding-top: 50px;
  margin-bottom: 50px;
  position: relative;
}

.title-page {
  text-align: center;
  text-transform: uppercase;
  font-size: 35px;

  &:before {
    content: '';
    display: inline-block;
    width: 60px;
    height: 15px;
    border-color: $color-info;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    margin: auto;
  }

  &:after {
    content: '';
    display: block;
    border-bottom: 5px solid $color-info;
    width: 57px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
  }

}


.special-block {
  background-color: #191919;
  padding: 50px 30px;
  position: relative;
  margin-bottom: 60px;

  .special-title {
      margin-bottom: 60px;
  }

  .special-title span {
      border-left: 1px solid rgba(238, 238, 238, 0.3);
  }

  .special-title span,
  .special-title h2 {
      padding: 0 0 0 15px;
  }

  h2 {
      position: relative;
      line-height: 1;
      border-left: 1px solid #eee;
      letter-spacing: 2px;
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 0;
      border-left: 1px solid rgba(238, 238, 238, 0.3);
  }

  h2:before {
      content: '';
      width: 1px;
      height: 25px;
      background: #11d6f0;
      position: absolute;
      left: -1px;
      bottom: 0;
  }

  &:before {
      border-color: #11d6f0 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #11d6f0;
      left: 0;
      top: 0;
  }

  &:after {
      border-color: rgba(0, 0, 0, 0) #11d6f0 #11d6f0 rgba(0, 0, 0, 0);
      bottom: 0;
      right: 0;
  }

  &:before,
  &:after {
      border-style: solid;
      border-width: 2px;
      content: "";
      display: block;
      height: 50px;
      position: absolute;
      width: 50px;
  }

  h4 {
      font-size: 18px;
      margin-bottom: 10px;
  }
}

.margin-bottom-30 {
  margin-bottom: 30px;
}